import React from "react"
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import RenderHTML from "../../components/renderHTML"
import styled from 'styled-components'
import customTheme from "../../utils/theme"
import { Heading, Box } from "@chakra-ui/core"
import { Link } from "gatsby"

const Wrapper = styled.div`
  background-color: ${customTheme.colors.lightgray};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
`

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 1rem;
  text-decoration: underline;

  &:hover {
    color: ${customTheme.colors.gold};
  }
`


const Page = ({ data }) => {

  //acfPageHome.map((value) => (console.log(value)))
  // for (const [key, value] of Object.entries(acfPageHome)) {
  //   console.log(key)
  //   console.log(value)
  // }
  //console.log(acfPageHome.carousel)
  /*
  const components = {
    carousel: block.Carousel,
    contentBlocks: block.ContentBlock,
    contentImageBlocks: block.ContentImageBlock,
    imageBlocks: block.ImageBlock,
    statBlocks: block.StatBlock,
    headlineBlocks: block.HeadlineBlock
  }*/


  console.log(data)

  return (

    <Layout>
      <Wrapper>
        <Box bg={customTheme.colors.blue} color={customTheme.colors.white} p={4} textAlign="center" fontSize={[ customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"] ]}>
          <Heading as="h1" fontWeight="400" mb={1} fontSize={[customTheme.fontSizes["xl"],customTheme.fontSizes["xl"],customTheme.fontSizes["2xl"],customTheme.fontSizes["3xl"],customTheme.fontSizes["4xl"]]}>{data.page.title}</Heading>
          <RenderHTML html={data.page.content} />
          <StyledLink to="/">Back to the home page.</StyledLink>
        </Box>
      </Wrapper>
    </Layout>
  )
}

export default Page;

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          remoteFile {
            ...HeroImage
          }
        }
      }
    }

    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }

    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`